import React, { Component } from "react";
import { Row, Col, Modal, Card, Table as Tbl } from "react-bootstrap";
import { faPlusCircle, faSyncAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { toast } from "react-toastify";

import moment from "moment";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";


import { Button, TextField } from "@material-ui/core";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faEye,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./../../images/ss.jpg";
import qrCodeImg from "./../../images/qr_code.jpg";


//API handling components
import { API_URL } from "../../global";
const axios = require("axios");

export default class GstBillHistory extends Component {
  constructor(props) {
    super();

    this.state = {
      billlist: null,
      showUpdateModel: false,
      activeBillId: null,
      activePaid: 0,
      discount: null,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      adjustment: 0,
      balance: 0,
      code: null,
      date: null,
      paid: 0,
      partyId: null,
      pname: null,
      total: 0,
      gst: 0,
      companyType: 1,
      mobileNo: null,
      paymentMode: 0,
      receiverName: null,
      itemsList: [],
      isLoadingItems: false,
      printComponentRef: null,
      tableTotal: 0,
      vehicle_charges: 0,
      loading_charges: 0,
      mobile:null
    };
  }

  fetchBillList = () => {
    let url = API_URL;
    const query = `SELECT gb.*, p.name as pname,mobile,p.address,companyType,discount FROM gstbill as gb inner join party as p where gb.partyId = p.id AND gb.status=1 ORDER BY gb.id DESC;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        this.setState({ billlist: res.data });
        // init data table
        this.initializeDataTable();
      })
      .catch((err) => {
      });
  };

  //  refreshProducts() {
  //     var startDate = this.state.startDate;
  //     var endDate = this.state.endDate;

  //     let url = API_URL;
  //     const query = `SELECT gb.*, p.name as pname,mobile,p.address,companyType,discount FROM gstbill as gb inner join party as p where (date <= '${endDate}' AND date >=  '${startDate}') AND gb.partyId = p.id AND gb.status=1 ORDER BY gb.id DESC`;
  //     let data = { crossDomain: true, crossOrigin: true, query: query };
  //     axios
  //       .post(url, data)
  //       .then((res) => {
  //         this.setState({ billlist: res.data });

  //         // init data table
  //         console.log(this.state.billlist)
  //         this.initializeDataTable();
  //       })
  //       .catch((err) => {
  //       });
  //   }

  refreshProducts() {
    // var data = this.state.billlist;
    var startDate = this.state.startDate;
    var endDate = this.state.endDate;
    var result = this.state.billlist.filter(function (obj) {
      return moment(obj.date).format("DD MMM yyyy") >= moment(startDate).format("DD MMM yyyy") && moment(obj.date).format("DD MMM yyyy") <= moment(endDate).format("DD MMM yyyy");
    })
    this.setState({ billlist: result });
    console.log(this.state.billlist)
    this.fetchBillList();

    // init data table
    this.initializeDataTable();
  }

  clearFilter() {
    window.location.reload();
  };

  fetchBillItemList = () => {
    let url = API_URL;
    const query = `SELECT bl.* FROM billlist as bl inner join gstbill as gb 
    on bl.billId=gb.id where bl.billId= ${this.state.activeBillId}`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        let total = 0
        res.data.map(d => {
          console.log("d : ", d)
          total += d.amount
        })
        this.setState({ itemsList: res.data, total: total });

        const query2 = `SELECT total, vehicle_charges, loading_charges from gstbill where id= ${this.state.activeBillId}`
        data = { crossDomain: true, crossOrigin: true, query: query2 };

        axios
          .post(url, data)
          .then((res) => {
            console.log("bill details res : ", res.data[0])
            this.setState({
              tableTotal: res.data[0].total,
              vehicle_charges: res.data[0].vehicle_charges,
              loading_charges: res.data[0].loading_charges
            });
            // init data table
            this.initializeDataTable();
          })

        // init data table
        // this.initializeDataTable();
      })
      .catch((err) => {
        console.log("")
      });
  };

  deleteRecord(id) {
    let url = API_URL;
    const query = `UPDATE gstbill SET status = 0  WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("Record deleted successfully");
        this.fetchBillList();
      })
      .catch((err) => {
      });
  }

  initializeDataTable() {
    $(document).ready(function () {
      $("#billlist").DataTable();
    });
  }

  initializeData() {
    this.fetchBillList();
  }
  componentDidMount() {
    this.initializeData();
  }

  handleUpdateSubmit(e) {
    let url = API_URL;
    const query = `UPDATE gstbill SET balance = total - ${this.state.activePaid}, paid = paid + ${this.state.activePaid} WHERE id=${this.state.activeBillId};`;
    console.log(query)
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("Bill details updated successfully");
        this.fetchBillList();
      })
      .catch((err) => {
      });
  }



  componentDidUpdate() {
    const title = "Party data -" + moment().format("DD-MMMM-YYYY");
    $("#billlist").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5],
          },
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5],
          },
        },
      ],
    });
  }

  renderMemoList = () => {
    if (this.state.billlist == null) return null;
    return this.state.billlist.map((bill, id) => {
      let color = bill.balance > 0 ? 'red' : '';
      return (
        <tr align="center" key={id}>
          <td>{bill.id}</td>
          <td>{bill.pname}</td>
          <td>{bill.total}</td>
          <td>{bill.paid}</td>
          <td style={{ color: color }}>{bill.balance}</td>
          <td>{moment(bill.date).format("DD / MM / YYYY")}</td>
          <td className="d-flex justify-content-center">
            &nbsp;
            <Button
              className="mt-2"
              color="primary"
              variant="contained"
              onClick={() => {
                this.setState(
                  {
                    activeBillId: bill.id,
                    showDisplayBillModal: true,
                    code: bill.code,
                    date: bill.date,
                    pname: bill.pname,
                    gstin: bill.gstin,
                    mobile: bill.mobile,
                    address: bill.address,
                    companyType: bill.companyType,
                    adjustment: bill.adjustment,
                    mobileNo: bill.mobile,
                    gst: bill.gstin,
                    discount: bill.discount,
                    paymentMode: bill.paymentMode,
                    receiverName: bill.receiverName,
                  },
                  this.fetchBillItemList
                );
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              className="mt-2"
              color="secondary"
              variant="contained"
              onClick={() => {
                this.setState({
                  activeBillId: bill.id,
                  activePaid: bill.paid,
                  showUpdateModel: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              className="mt-2"
              variant="contained"
              onClick={(e) => {
                var pin = window.prompt("Delete the item?")
                if (pin == 280956) {
                  this.deleteRecord(bill.id);
                }
                else {
                  toast.error("Entered wrong pin");
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });

  };

  renderUpdateModal = () => {
    return (
      <Modal
        show={this.state.showUpdateModel}
        onHide={(e) => this.setState({ showUpdateModel: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "center" }}>
            Update bill
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col xs={12}>
                  <TextField
                    id="pending"
                    label="Paid"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    value={this.state.activePaid}
                    onChange={(e) =>
                      this.setState({
                        activePaid: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>

            <div className="mt-2 mr-1">
              <Button
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModel: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  };

  renderDisplayBillModal = () => {
    return (
      <Modal
        show={this.state.showDisplayBillModal}
        onHide={(e) => this.setState({ showDisplayBillModal: false })}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bill details
          </Modal.Title>
        </Modal.Header>
        <div className="mt-1 measure" ref={(el) => (this.printComponentRef = el)}>
          <h6 className="text-center bg-info py-1" style={{ marginBottom: '0em', marginTop: '-.8em' }}>
            TAX INVOICE
          </h6>
          <Row>
            <Col className="mx-auto">
              <Card className="mt-2 p-0">
                <Card.Header>
                  <Row>
                    <div className="col-3">
                      <img
                        src={logo}
                        width="150"
                        height="100"
                        alt="logo"
                      />
                    </div>
                    <div className="col-9 pb-2 mx-0">
                      <div className="text-left pb-0 mb-0">
                        <b className="h3">
                          ओम साई डिस्ट्रीब्युटर्स
                        </b>
                        <p style={{ fontSize: "11px", marginTop: "12px", }}>जीरा सोडा, कोल्ड्रिंक्स, ज्यूस, बिस्कीट, बेकरी उत्पादने,पिण्याचे पाणी, डिटर्जेंट पावडर व साबण व इतर सर्व वस्तूंचे होलसेल व्यापारी</p>
                        <hr style={{ width: '100%' }} />
                        <p
                          className="text-start pb-0 mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          सिद्धिविनायक कॉलनी, पाटबंधारे विभागाच्या पाठीमागे, कोकरूड रोड, ३२ शिराळा, सांगली - 415408
                        </p>
                      </div>
                    </div>
                  </Row>
                  <hr />
                  <div className="text-center">

                    <p >
                      Phone No.: राहुल आनंदा वरेकर - 7399119111
                    </p>

                  </div>

                  <hr />
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>
                      Invoice No.{" "}
                      <b>{this.state.activeBillId}</b>
                    </p>
                    <p>
                      Date{" "}
                      <b>
                        {moment(this.state.date).format(
                          "D/M/YYYY"
                        )}
                      </b>
                    </p>
                  </span>
                </Card.Header>
                <Card.Body className="pb-3 mb-0">
                  <Row>
                    <div className="col-6 col-md-6">
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        Party name:{" "}
                        <b>
                          {this.state.pname ||
                            this.state.newPartyName}
                        </b>
                      </h6>
                    </div>
                    <div className="col-6 col-md-6">
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        Address:{" "}
                        <b>{this.state.address}</b>
                      </h6>
                    </div>
                    <div className="col-6 col-md-6">
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        GSTIN: <b>{this.state.gst}</b>
                      </h6>
                    </div>
                    <div className="col-6 col-md-6">
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        Mobile No.:{" "}
                        <b>{this.state.mobileNo}</b>
                      </h6>
                    </div>

                  </Row>
                </Card.Body>
                <Card.Body className="m-0 pt-0">
                  {/* Order overview */}
                  <Tbl striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Particular</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    {this.state.itemsList.length > 0 ? (
                      <tbody>
                        {this.state.itemsList.map(
                          (item, index) => {
                            return (
                              // <tr key={"" + item.particularValue.title}>
                              //   <td>{item.particularValue.title} </td>
                              <tr key={index}>
                                <td>
                                  {
                                    item.particular
                                  }
                                </td>
                                <td>
                                  {
                                    item.quantity
                                  }
                                </td>
                                <td>
                                  {item.rate}
                                </td>
                                <td>
                                  {
                                    item.amount
                                  }
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <br></br>
                        <tr>
                          <td colSpan="3">
                            Total amount
                            before tax
                          </td>
                          <td colSpan="2">
                            {
                              this.state
                                .total
                            }
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            SGST 9%
                          </td>
                          <td colSpan="2">
                            {
                              Number((this.state.total / 100) * 9).toFixed(2)
                            }
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            CGST 9%
                          </td>
                          <td colSpan="2">
                            {
                              Number((this.state.total / 100) * 9).toFixed(2)
                            }
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            IGST 18%
                          </td>
                          <td colSpan="2">
                            {
                              Number((this.state.total / 100) * 18).toFixed(2)
                            }
                          </td>
                        </tr>
                        {
                          this.state.loading_charges ?
                            <tr>
                              <td colSpan="3">
                                Loading Charges
                              </td>
                              <td colSpan="2">
                                {
                                  this.state.loading_charges
                                }
                              </td>
                            </tr>
                            :
                            ""
                        }
                        {
                          this.state.vehicle_charges ?
                            <tr>
                              <td colSpan="3">
                                Vehicle Charges
                              </td>
                              <td colSpan="2">
                                {
                                  this.state.vehicle_charges
                                }
                              </td>
                            </tr>
                            :
                            ""
                        }
                        <tr>
                          <td colSpan="3">
                            <b>Grand Total</b>
                          </td>
                          <td colSpan="2">
                            <b>
                              {
                                this.state.tableTotal
                              }
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="6">
                            No items added
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Tbl>
                </Card.Body>
                <Card.Footer className="pb-3 mb-0">
                  <Row>
                    <Col>
                      {/* <h6
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                GSTIN No.: <b>27AOLPK5202K1ZU</b>
                                            </h6> */}
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {/* Date: 28/06/2017 */}
                      </h6>
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        State : Maharashtra
                      </h6>
                      <h6
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        Code: 27
                      </h6>
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                      <img src={qrCodeImg} height="85%" width="75%" alt="qr code" />
                    </Col>
                    <Col>
                      <table style={{ borderCollapse: "collapse" }}>
                        <tbody>
                          <tr>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px", }}>Account Holder</td>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px", fontWeight: "bold" }}>OM GAGANGIRI DISTRIBUTORS</td>
                          </tr>
                          <tr>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>Account Number</td>
                            <td style={{ textTransform: "capitalize", padding: "3px", padding: "3px", fontSize: "10px", fontWeight: "bold" }}>50200073571261</td>
                          </tr>
                          <tr>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>IFSC</td>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px", fontWeight: "bold" }}>HDFC0002455</td>
                          </tr>
                          <tr>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>Branch</td>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px", fontWeight: "bold" }}>ISLAMPUR URAN</td>
                          </tr>
                          <tr>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>Account Type</td>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>CURRENT</td>
                          </tr>
                          <tr>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>Virtual Payment Address</td>
                            <td style={{ textTransform: "capitalize", padding: "3px", fontSize: "10px" }}>8329738137@hdfcbank</td>
                          </tr>
                        </tbody>
                      </table>



                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <div className="col-12" style={{ textAlign: "center", color: "grey" }}>developed by 5TechG Lab | M:7028828831</div>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        
        </div>
      </Modal>
    );
  };

  render() {
    console.log("state.total : ", this.state.total)
    return (
      <div>
        <div className="row">
          <form autoComplete="off">
            <div className="row ml-4 mt-4">

              <TextField
                id="Start Date"
                label="Date From"
                variant="outlined"
                type="date"
                className="mr-2"
                required={true}
                size="small"
                value={this.state.startDate}
                onChange={(e) =>
                  this.setState({ startDate: e.target.value })
                }
              />


              <TextField
                id="End date"
                label="Date To"
                variant="outlined"
                type="date"
                className="mr-2"
                size="small"
                value={this.state.endDate}
                onChange={(e) =>
                  this.setState({ endDate: e.target.value })
                }
              />

              <Button
                color="primary"
                variant="contained"
                className="mb-3 ml-2"
                onClick={(e) => { this.refreshProducts() }}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>

              <Button
                color="secondary"
                variant="contained"
                className="ml-3 p-1"
                size="small"
                style={{ height: '40px' }}
                onClick={(e) => { this.clearFilter() }}
              >
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="2x"
                />
              </Button>
            </div>
          </form>
          <ToastContainer />
        </div>
        <Row>
          <Col
            md="12"
            className="m-0 p-1 measure1"
            style={{ minHeight: "85vh" }}
          >
            <div>
              <table id="billlist" data-order='[[ 0, "desc" ]]' className="display" style={{ width: "100%" }}>
                <thead>
                  <tr align="center">
                    <th align="center">Bill Id</th>
                    <th align="center">Party Name</th>
                    <th align="center">Total</th>
                    <th align="center">Paid</th>
                    <th align="center">Balance</th>
                    <th align="center">Date</th>
                    <th align="center">Actions</th>

                  </tr>
                </thead>
                <tbody>{this.renderMemoList()}</tbody>
              </table>
              {this.renderUpdateModal()}
              {this.renderDisplayBillModal()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}